<template>
    <Button
        icon="pi pi-whatsapp"
        class="p-button-success pb-3 pr-4 pl-4 pt-2"
        :disabled="disabled"
        @click="onClickConfirmEnvioWhats()"
        v-tooltip.top="'Enviar WhatsApp'"
    />
    <AppEnvioWhatsDialog
        v-if="ctx.showEnvioWhatsDialog"
        v-model:visible="ctx.showEnvioWhatsDialog"
        :text="ctx.corpoMensagemWhats"
        :phone="phone"
        @onConfirm="onClickConfirmEnvioWhats"
        @onClose="onClickCloseEnvioWhatsDialog"
    />
</template>
<script setup>
import { defineProps, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
import AppEnvioWhatsDialog from '@/components/AppEnvioWhatsDialog.vue';
import { ConfirmacaoManualWhatsapp } from '@/pages/painel-agendamento/components/confirmacao-manual-whatsapp/confirmacao-manual-whatsapp';
import { verificarExameOcupacional } from '@/utils/VerificarExameOcupacional';
import moment from '@/utils/Moment';

const props = defineProps({
    disabled: Boolean,
    appointmentSelected: Object,
    phone: String
});
const $toast = useToast();
const ctx = reactive({
    showEnvioWhatsDialog: false,
    corpoMensagemWhats: ''
});

function onClickConfirmEnvioWhats() {
    const ocupacional = verificarExameOcupacional(props.appointmentSelected.tipoExameId);
    if (ocupacional) {
        setCorpoMensagemOcupacional();
    } else {
        setCorpoMensagemNaoOcupacional();
    }
}

function setCorpoMensagemOcupacional() {
    const dadosExameClinico = props.appointmentSelected.agendamentoProcedimentos.find((data) => {
        if (data.procedimentos.clinical) {
            return { data };
        }
    });

    if (!dadosExameClinico) {
        $toast.add({
            severity: 'warn',
            summary: 'Agendamento não possui exame clínico',
            detail: 'Informe os valores não informado na mensagem',
            life: 10000
        });
    }
    ctx.corpoMensagemWhats = new ConfirmacaoManualWhatsapp().obterTexto(props.appointmentSelected, dadosExameClinico);
    ctx.showEnvioWhatsDialog = true;
}

function setCorpoMensagemNaoOcupacional() {
    const procedimentos = props.appointmentSelected.agendamentoProcedimentos.slice();
    const [exameMaisProximo] = procedimentos.sort(dataMaisAntiga);

    if (!exameMaisProximo) {
        $toast.add({
            severity: 'warn',
            summary: 'Não foi encontrado exame.',
            detail: 'Informe os valores não informado na mensagem',
            life: 10000
        });
    }
    ctx.corpoMensagemWhats = new ConfirmacaoManualWhatsapp().obterTexto(props.appointmentSelected, exameMaisProximo);
    ctx.showEnvioWhatsDialog = true;
}

function dataMaisAntiga(procedA, procedB) {
    const anterior = procedA.dataAgendamento;
    const procedimentoAconteceAntes = moment(procedB.dataAgendamento).isBefore(anterior) ? 1 : -1;
    return procedimentoAconteceAntes;
}

function onClickCloseEnvioWhatsDialog() {
    ctx.showEnvioWhatsDialog = false;
    ctx.corpoMensagemWhats = null;
}
</script>
