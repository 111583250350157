import { useGlobalFilters } from '@/utils/useGlobalFilters';

export class ConfirmacaoManualWhatsapp {
    $filters = useGlobalFilters();

    obterTexto(appointmentSelected, dadosExameClinico) {
        const rua = dadosExameClinico?.prestador?.street ?? 'Não informado';
        const numero = dadosExameClinico?.prestador?.number ?? 'Não informado';
        const bairro = dadosExameClinico?.prestador?.neighborhood ?? 'Não informado';
        const cidade = dadosExameClinico?.prestador?.city?.cidade
            ? dadosExameClinico?.prestador?.city?.cidade + ' - ' + dadosExameClinico?.prestador?.city?.estado
            : 'Não informado';
        const complemento = dadosExameClinico?.prestador?.addressComplement ? 'Complemento: ' + dadosExameClinico.prestador.addressComplement : '';
        const cep = dadosExameClinico?.prestador?.zipCode ?? 'Não informado';

        const enderecoPrestadorClinico = `Rua: ${rua} Nº: ${numero}, Bairro: ${bairro} ${complemento}, Cidade: ${cidade}, CEP: ${cep}`;

        const corpoMensagemWhats = `Olá *${appointmentSelected?.funcionario?.name}*, tudo bem ???? Faço parte da equipe da ${
            appointmentSelected?.company.name
        }, e estou entrando em contato para informar sobre o agendamento do seu exame ${appointmentSelected?.tipoExame?.descricao} pela empresa *${
            appointmentSelected?.cliente?.tradeName
        }*. Seu exame está agendado para o dia *${
            dadosExameClinico?.dataAgendamento ? this.$filters.formatDate(dadosExameClinico?.dataAgendamento) : 'Não Informado'
        }* na clinica: *${
            dadosExameClinico?.prestador?.name ? dadosExameClinico.prestador.name : 'Não informado'
        }* Endereço: *${enderecoPrestadorClinico}*. O agendamento só será realizado, mediante a confirmação desta mensagem!`;
        return corpoMensagemWhats;
    }
}
